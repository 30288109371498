<template>
  <div ref="container" class="http404-container">
    <div class="http404">
      <img class="http404__img" src="@/assets/404_images/404.png" alt="404">
      <div class="http404__title"><span>哎呀，页面飞到火星去了~</span></div>
      <div class="http404__button-wrapper">
        <van-button class="http404__button" type="primary" round @click="$router.go(-1)">返回</van-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Page404'
}
</script>

<style lang="scss" scoped>
  .http404-container {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: center;
    transform: translateY(-12%);
    .http404 {
      &__img {
        position: relative;
        left: 20%;
        width: 80%;
      }
      &__title {
        text-align: center;
        margin-top: 10px;
        font-size: 15px;
        color: #C8C9CC;
      }
      &__button-wrapper {
        text-align: center;
        margin-top: 30px;
      }
      &__button {
        width: 80%;
        font-size: 16px;
      }
    }
  }
</style>
