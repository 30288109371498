var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "container",
    staticClass: "http404-container"
  }, [_c('div', {
    staticClass: "http404"
  }, [_c('img', {
    staticClass: "http404__img",
    attrs: {
      "src": require("@/assets/404_images/404.png"),
      "alt": "404"
    }
  }), _vm._m(0), _c('div', {
    staticClass: "http404__button-wrapper"
  }, [_c('van-button', {
    staticClass: "http404__button",
    attrs: {
      "type": "primary",
      "round": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("返回")])], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "http404__title"
  }, [_c('span', [_vm._v("哎呀，页面飞到火星去了~")])]);
}]

export { render, staticRenderFns }